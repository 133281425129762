

































































































































































































































































































































































.form {
  .fromTitle {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .el-form-item {
    margin-bottom: 10px;
  }
}

.btn-box {
  margin-bottom: 10px;
}
